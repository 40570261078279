<template>
  <div class="creator-step">
    <div class="creator-step-title">{{ $locale.words["verify_identity"] }}</div>
    <form class="creator-step-3-form" v-if="isStep2Complete" v-on:submit.prevent>
      <div class="creator-step-content">
        <div class="semantic">
          <div>{{ $locale.words["verify_upload_desc"] }}</div>
          <ol class="require-photos">
            <li>{{ $locale.words["verify_document_front"] }}</li>
            <li>{{ $locale.words["verify_document_back"] }}</li>
            <li>{{ $locale.words["verify_face_desc"] }}</li>
          </ol>
        </div>
        <div class="creator-step-files">
          <div class="creator-step-doc">
            <div class="creator-step-doc-title">{{ $locale.words["document_id_front"] }}</div>
            <InputImage width="100%" name="id_front" :value="getValue('id_front')" />
          </div>
          <div class="creator-step-doc">
            <div class="creator-step-doc-title">{{ $locale.words["document_id_back"] }}</div>
            <InputImage width="100%" name="id_back" :value="getValue('id_back')" />
          </div>
          <div class="creator-step-doc">
            <div class="creator-step-doc-title">{{ $locale.words["document_id_face"] }}</div>
            <InputImage width="100%" name="face_id" :value="getValue('face_id')" />
          </div>
        </div>
      </div>
      <div class="creator-step-options">
        <div>{{ $locale.words["verify_privacy_files"] }}</div>
      </div>
      <div class="creator-step-options">
        <GradientButton
          :label="$locale['words']['continue']"
          to="/user/verify?apply=step3"
          @click.native="goToVerification"
          icon="arrowRight"
          dir="rtl"
        />
      </div>
    </form>
    <div v-else>
      <div class="creator-step-content">
        {{ $locale.words["plaease_complete"] + " " + $locale.words["step"] + " 2" }}
      </div>
      <div class="creator-step-options">
        <GradientButton :label="$locale['words']['go_back']" to="/user/verify?apply=step2" icon="arrowLeft" dir="ltr" />
      </div>
    </div>
    <VerifyUserSteps3Confirm ref="confirm" v-if="showConfirm" />
  </div>
</template>

<script>
export default {
  components: {
    InputImage: () => import("../inputs/InputImage.vue"),
    VerifyUserSteps3Confirm: () => import("./VerifyUserSteps3Confirm.vue"),
  },
  data: function() {
    return {
      showConfirm: false,
    };
  },
  methods: {
    goToVerification: function() {
      this.showConfirm = true;
      this.$refs?.confirm?.modalOpen();
    },
    getValue: function(key) {
      let dummys = {
        id_front: "https://myfans1a.s3.amazonaws.com/miscellaneous/user_id_front.png",
        id_back: "https://myfans1a.s3.amazonaws.com/miscellaneous/user_id_back.png",
        face_id: "https://myfans1a.s3.amazonaws.com/miscellaneous/user_id_and_face.png",
      };

      return this.$profile.user?.identity[key]?.location || dummys[key];
    },
  },
  computed: {
    isStep2Complete: function() {
      return this.$store.state.creator.apply.step2;
    },
  },
};
</script>
